import { result, includes, isEqual, each, difference, assign } from 'lodash';
import { View, BoundModel } from 'backbone-component';
import { changer } from '../utils';
import App from '../app';
export default View.extend({
  defaults: {},
  conversions: {},
  excludeFromQuerystring: [],
  constructor: function PageView() {
    var _this = this;
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    // Accessing `this` before super isn't great, but needed pre-initialize
    this.defaults = result(this, 'defaults', {});
    var getDefaults = function getDefaults() {
      return isModel(_this.defaults) ? _this.defaults.attributes : _this.defaults || {};
    };
    this.handlebarsRuntimeOptions = {
      allowedProtoMethods: {
        toEditTitle: true
      },
      allowedProtoProperties: {
        config: true
      }
    };
    this.conversions = result(this, 'conversions', {});
    this.excludeFromQuerystring = result(this, 'excludeFromQuerystring', []);
    this.model = new BoundModel(getDefaults());
    View.call(this, options);

    // Querystring handling
    var toQuery = function toQuery() {
      return valuesToQuery(_this.model.attributes, _this.excludeFromQuerystring, getDefaults());
    };
    var fromQuery = function fromQuery(query) {
      return valuesFromQuery(query, _this.conversions);
    };
    var change = changer();
    var active = false;
    var initialized = false;
    this.listenTo(this.model, 'change', function () {
      if (!active) return;
      change(function () {
        return App.router.setQuerystring(toQuery());
      });
    });

    // Handle changing default values
    if (isModel(this.defaults)) {
      this.listenTo(this.defaults, 'change', function () {
        App.router.setQuerystring(toQuery(), {
          replace: true
        });
      });
    }

    // Render on activate
    this.on('activate', function () {
      active = true;
      if (!initialized) {
        change(function () {
          var values = fromQuery(App.router.getQuerystring());
          // If the us_state is set in the query string, it needs to be set FIRST
          // Otherwise, the default values for us_state will override the custom query string values
          if ('us_state' in values) {
            _this.model.set('us_state', values.us_state);
            delete values.us_state;
          }
          ;
          _this.model.set(values);
        });

        // After initial activate, page is in charge of re-render
        _this.render();
        initialized = true;
      } else {
        change(function () {
          App.router.setQuerystring(toQuery());
        });
      }
      if (_this.title) {
        App.view.setTitle(result(_this, 'title'));
      }
    });
    this.on('deactivate', function () {
      active = false;
    });
    this.on('update', function () {
      change(function () {
        var values = fromQuery(App.router.getQuerystring());

        // For update:
        // - Reset unset values to defaults
        // - Carry over excluded from previous
        // - Remove previous values that aren't defaults or excluded
        // - Set values from querystring
        var previous = _this.model.attributes;
        var defaults = isModel(_this.defaults) ? _this.defaults.attributes : _this.defaults || {};
        var excluded = _this.excludeFromQuerystring;
        var unset = difference(Object.keys(previous), Object.keys(values).concat(Object.keys(defaults)).concat(excluded));
        unset.forEach(function (key) {
          return _this.model.unset(key);
        });
        var set = assign({}, defaults, values);
        _this.model.set(set);
      });
    });
  }
});
function valuesFromQuery(query) {
  var conversions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var converted = {};
  each(query, function (value, key) {
    var convert = conversions[key];
    converted[key] = convert ? convert(value) : value;
  });
  return converted;
}
function valuesToQuery(values) {
  var exclude = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var defaults = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var query = {};
  each(values, function (value, key) {
    if (!includes(exclude, key) && !isEqual(value, defaults[key])) {
      query[key] = value;
    }
  });
  return query;
}
function isModel(value) {
  return value && value.attributes && value.get;
}