import $ from 'jquery';
import { extend, isFunction, defer } from 'lodash';
import { geo as d3_geo, select as d3_select } from 'd3';
// topojson needs context
var topojson = require('topojson');
import { Chart, Overlay, mixins, helpers, Legend, InsetLegend } from 'd3-compose';
import { us_topology } from '../topology';
var di = helpers.di;
var property = helpers.property;
var translate = helpers.translate;
var LABEL_HEIGHT = 22;
var states_small = ['VT', 'NH', 'MA', 'RI', 'CT', 'NJ', 'DE', 'MD', 'DC'];
var states_small_start = [-67.707617, 42.722131];
var states_offsets = {
  FL: {
    x: -2.5
  },
  KY: {
    x: -2.5
  },
  MI: {
    x: -2.5,
    y: 18
  },
  NY: {
    x: -1
  },
  LA: {
    x: 13
  },
  WV: {
    x: 11
  }
};
var HI_start = [-107.507617, 24.722131];

// v3 approximation of d3.local
var centroidLocal = {
  get: function get(element) {
    return element.__centroid;
  },
  set: function set(element, value) {
    element.__centroid = value;
  }
};
var Mixed = helpers.mixin(Chart, mixins.XY, mixins.StandardLayer, mixins.Hover);
Mixed.extend('Choropleth', {
  initialize: function initialize(options) {
    Mixed.prototype.initialize.call(this, options);
    var layers = {
      states: this.base.append('g').classed('chart-states', true),
      labels: this.base.append('g').classed('chart-labels', true),
      us: this.base.append('g').classed('chart-us', true)
    };
    this.layer('chart-states', layers.states, {
      dataBind: function dataBind() {
        return this.selectAll('path').data(this.chart().features, function (d) {
          return d.id;
        });
      },
      insert: function insert() {
        var chart = this.chart();
        var stateClass = chart.stateClass();
        var width = chart.width();
        var height = chart.height();
        var onStateMouseenter = chart.onStateMouseenter,
          onStateMouseleave = chart.onStateMouseleave,
          onStateClick = chart.onStateClick;
        var scale = getScale(width, height);
        var translate = getTranslate(width, height);
        var projection = chart.projection = d3_geo.albersUsa().scale(scale).translate(translate);
        var path = chart.path = d3_geo.path().projection(projection);

        // Note: only inserting (no need for entry/exit events because chart stays the same except for styling of states
        var return_val = this.append('path').attr('data-state', function (d) {
          return d.id;
        }).attr('class', stateClass).attr('d', path).each(function (d) {
          // Instead of centroid, take the right-most x value and centroid's vertical center
          var bounds = path.bounds(d);
          var y = path.centroid(d)[1];
          centroidLocal.set(this, {
            left: [bounds[0][0], y],
            right: [bounds[1][0], y]
          });
        });
        if (options.can_hover) {
          return_val.on('mouseenter', onStateMouseenter).on('mouseleave', onStateMouseleave);
        }
        if (options.can_click_states) {
          return_val.on('click', onStateClick).on('touchend', function (d, i) {
            onStateClick.call(this, d, i);
            this.clicked = true;
          });
        }
        return return_val;
      },
      events: {
        merge: function merge() {
          var chart = this.chart();
          var stateFill = chart.stateFill();
          var selected = chart.selected();
          this.style('fill', stateFill).classed('is-selected', function (d) {
            var is_selected = d.id == selected;
            if (is_selected) moveToTop(this);
            return is_selected;
          });
        }
      }
    });
    this.layer('Labels', layers.labels, {
      dataBind: function dataBind() {
        return this.selectAll('g').data(this.chart().features, function (d) {
          return d.id;
        });
      },
      insert: function insert() {
        var _this = this;
        var chart = this.chart();
        var return_val = this.append('g').attr('class', 'state-label').attr('data-state', function (d) {
          return d.id;
        }).each(chart.drawLabel);
        if (options.can_hover) {
          return_val.on('mouseenter', function (d) {
            return chart.onStateMouseenter.call(getElement(d.id), d);
          }).on('mouseleave', function (d) {
            return chart.onStateMouseleave.call(getElement(d.id), d);
          });
        }
        if (options.can_click_states) {
          return_val.on('click', function (d) {
            return chart.onStateClick.call(getElement(d.id), d);
          }).on('touchend', function (d) {
            chart.onStateClick.call(getElement(d.id), d);
            _this.clicked = true;
          });
        }
        return return_val;
        function getElement(id) {
          return layers.states.select("[data-state=".concat(id, "]")).node();
        }
      },
      events: {
        merge: function merge() {
          var chart = this.chart();
          var stateFill = chart.stateFill();
          var selected = chart.selected();
          this.classed('is-selected', function (d) {
            return d.id == selected;
          }).select('rect').style('fill', stateFill);
        }
      }
    });
    this.layer('us', layers.us, {
      dataBind: function dataBind() {
        return this.selectAll('g').data([null]);
      },
      insert: function insert() {
        var chart = this.chart();
        var us_data = chart.data().US;
        var group = this.append('g').classed('chart-us-group', true);
        if (options.can_hover) {
          group.on('mouseenter', function () {
            // Can't quite use state enter/leave, approximate for US
            group.classed('is-hovered', true);
            var point = {
              coords: centroidLocal.get(this),
              value: chart.getText()(us_data, {
                id: 'US'
              }, chart.stateFill(), chart.labelColor()),
              id: 'US',
              properties: us_data
            };
            chart.container.trigger('mouseenter:state', point);
          }).on('mouseleave', function () {
            group.classed('is-hovered', false);
            chart.container.trigger('mouseleave:state', {
              id: 'US',
              properties: chart.data().US
            });
          });
        }
        if (options.can_click_states) {
          group.on('click', function () {
            chart.onStateClick.call(this, {
              id: 'US',
              properties: us_data
            });
          }).on('touchend', function () {
            chart.onStateClick.call(this, {
              id: 'US',
              properties: us_data
            });
            this.clicked = true;
          });
        }
        var labelColor = chart.labelColor();
        var color = isFunction(labelColor) ? labelColor({
          id: 'US'
        }) : labelColor;

        // TODO: extract positioning to config
        group.append('rect').attr('class', 'us-clickable-area');
        group.append('rect').attr('class', 'us-label-bg').attr('width', 90).attr('height', 65) //LABEL_HEIGHT + 13)
        .attr('x', 425).attr('y', 324);
        // group.append('text')
        //   .attr('class', 'us-label-text mod-callout')
        //   .text('US')
        //   .attr('x', -10)
        //   .attr('y', LABEL_HEIGHT / 2 + 2)
        //   .style({fill: color});
        group.append('text').attr('class', 'us-description').text('United') //chart.data().US.name)
        .attr('x', 440).attr('y', 350) //LABEL_HEIGHT / 2 - 38);
        .style({
          fill: color
        });
        group.append('text').attr('class', 'us-description').text('States').attr('x', 440).attr('y', 380) //LABEL_HEIGHT / 2 - 38);
        .style({
          fill: color
        });
        return group;
      },
      events: {
        merge: function merge() {
          var chart = this.chart();

          // Position clickable area behind label + description
          var bbox = this.node().getBBox();
          this.select('.us-clickable-area').attr('width', bbox.width).attr('height', bbox.height).attr('transform', helpers.translate(bbox.x, bbox.y));
          this.select('.us-label-bg').style('fill', chart.stateFill()({
            id: 'US'
          }));

          // Position label + description at bottom, center of chart
          var x = chart.width() / 2 - bbox.width / 2 - 80;
          var y = 0;
          this.attr('transform', helpers.translate(x, y)).classed('is-selected', chart.selected() == 'US').each(function () {
            var width = this.getBBox().width;
            var x = chart.width() - width - 85;
            var y = chart.height() - 120;
            centroidLocal.set(this, {
              left: [x, y],
              right: [x + width + 12, y]
            });
          });
        }
      }
    });
  },
  features: topojson.feature(us_topology, us_topology.objects.usa).features,
  getValue: property({
    default_value: function default_value(d) {
      return d;
    }
  }),
  getText: property({
    default_value: function default_value(d) {
      return d;
    }
  }),
  stateClass: property({
    default_value: function default_value() {
      return function (d) {
        return "state ".concat(d.id);
      };
    }
  }),
  stateFill: property({
    default_value: function default_value() {
      return '';
    }
  }),
  labelColor: property({
    default_value: function default_value() {
      return '#000';
    }
  }),
  selected: property(),
  drawLabel: di(function (chart, d) {
    var group = d3_select(this);
    var stateFill = chart.stateFill();
    var center = chart.path.centroid(d);
    var start_coordinates = chart.projection(states_small_start);
    var offset = states_offsets[d.id] || {};
    offset.x = 'x' in offset ? offset.x : 7.5;
    offset.y = 'y' in offset ? offset.y : 5;
    var x = center[0] - offset.x;
    var y = center[1] + offset.y;
    var small_state_index = states_small.indexOf(d.id);
    var text;
    if (small_state_index > -1 || d.id == 'HI') {
      var center_offset_x = 0;
      var center_offset_y = 0;
      if (small_state_index > -1) {
        x = start_coordinates[0];
        y = start_coordinates[1] + small_state_index * (2 + LABEL_HEIGHT);
      } else if (d.id == 'HI') {
        var HI_coordinates = chart.projection(HI_start);
        x = HI_coordinates[0];
        y = HI_coordinates[1];
        center_offset_x = 3;
        center_offset_y = 2;
      }
      group.append('line').attr('x1', x - 5).attr('y1', y - LABEL_HEIGHT / 4).attr('x2', center[0] + center_offset_x).attr('y2', center[1] + center_offset_y).attr('class', 'state-label-callout');
      group.append('rect').attr('x', x - 6).attr('y', y - LABEL_HEIGHT / 2 - 3).attr('width', 32).attr('height', LABEL_HEIGHT - 3).style('fill', stateFill).classed('state-label-container', true).attr('data-state', d.id);
      text = group.append('text').classed('mod-callout', true);
    } else {
      text = group.append('text');
    }
    var labelColor = chart.labelColor();
    var color = isFunction(labelColor) ? labelColor(d) : labelColor;
    text.attr('x', x).attr('y', y).classed('state-label-text', true).attr('data-state', d.id).style({
      fill: color
    }).text(d.id);
  }),
  onStateMouseenter: di(function (chart, d) {
    if (chart.current == d.id) {
      return;
    }
    chart.current = d.id;
    chart.base.selectAll('.state, .state-label-container, .state-label').classed('is-hovered', function (state) {
      return state.id == d.id;
    });

    // Move to front on hover for proper state border
    moveToTop(this);

    // Move selected state to front (on top of is-hovered state)
    moveToTop(chart.base.select('.state.is-selected').node());
    var coords = centroidLocal.get(this);
    var value = chart.getText()(chart.data()[d.id], d, chart.stateFill(), chart.labelColor());
    var point = extend({
      coords: coords,
      value: value
    }, d);
    chart.container.trigger('mouseenter:state', point);
  }),
  onStateMouseleave: di(function (chart, d) {
    chart.base.selectAll('.state, .state-label-container, .state-label').classed('is-hovered', false);
    chart.container.trigger('mouseleave:state', d);
    chart.current = null;
  }),
  onStateClick: di(function (chart, d) {
    var _this2 = this;
    if (this.clicked) {
      this.clicked = false;
      return;
    }
    chart.current = null;
    chart.container.trigger('click:state', d.id);

    // Force mouseover after choropleth re-renders
    defer(function () {
      return chart.onStateMouseenter.call(_this2, d);
    });
  })
});
function getScale(width, height) {
  // height -> scale
  // 335 -> ~700, 415 -> ~900 => 2.1
  var scaling_factor = 2.1;
  var scale = Math.round(scaling_factor * height);

  // TODO check width scale and take minimum

  return scale;
}
function getTranslate(width, height) {
  var y_adjustment = 0.97;
  var x_adjustment = 1;
  return [width / 2 * x_adjustment, height / 2 * y_adjustment];
}
Legend.registerSwatch('Choropleth', function (chart, d) {
  var dimensions = chart.swatchDimensions();
  this.append('rect').attr('width', dimensions.width).attr('height', dimensions.height).attr('x', 0).attr('y', 0).attr('fill', d.fill);
});
Overlay.extend('Popover', {
  initialize: function initialize(options) {
    var _this3 = this;
    var hiding;
    this.base.classed('chart-popover', true);
    this.on('attach', function () {
      _this3.container.on('mouseenter:state', function (d) {
        clearTimeout(hiding);
        _this3.render(d, options);
        _this3.show();
      });
      _this3.container.on('mouseleave:state', function () {
        hiding = setTimeout(function () {
          return _this3.hide();
        }, 250);
      });
    });
  },
  render: function render(d, options) {
    var _this4 = this;
    var is_small_screen = document.body.clientWidth < 500;
    if (is_small_screen) return;
    var show_on_left = d.coords.left[0] > 450;
    this.position({
      chart: {
        x: show_on_left ? d.coords.left[0] : d.coords.right[0],
        y: show_on_left ? d.coords.left[1] : d.coords.right[1]
      }
    });
    this.title = '';
    if (options.include_overlay_state_title) this.title = d.properties.name || 'Title placeholder';
    this.content = d.value || 'Content placeholder';
    this.placement = show_on_left ? 'left' : 'right';

    // Add bootstrap popover (if needed) and show
    var base = $(this.base);
    if (!this.created) {
      base.popover({
        placement: function placement() {
          return _this4.placement;
        },
        trigger: 'manual',
        container: base,
        html: true,
        content: function content() {
          return _this4.content;
        },
        title: function title() {
          return _this4.title;
        },
        animation: false
      });
      this.created = true;
    }
    base.popover('show');
  },
  //Override default overlay type's style property

  style: property({
    default_value: function default_value() {
      // Bootstrap popover requires absolute positioning of element
      // (rather than css transform, which is the default)

      var styles = {
        position: 'absolute',
        top: this.y() + 'px',
        left: this.x() + 'px',
        opacity: this.hidden() ? 0 : 1
      };
      return helpers.style(styles);
    }
  })
});
InsetLegend.extend('ScaleLegend', {
  draw: function draw(data) {
    Legend.prototype.draw.call(this, data);
    var chart = this;
    var _this$props = this.props,
      swatchDimensions = _this$props.swatchDimensions,
      legendTitle = _this$props.legendTitle; // Reverse layout of first group
    this.base.selectAll('.chart-legend-group:first-child').each(function () {
      var bbox = this.getBBox();
      var selection = d3_select(this);
      var label = selection.select('.chart-legend-label');
      var swatch = selection.select('.chart-legend-swatch');
      var existing_label_transform = label.attr('transform').replace('translate(', '').replace(')', '').split(',').map(function (part) {
        return Number(part.trim());
      });
      label.attr('transform', helpers.translate(0, existing_label_transform[1]));
      swatch.attr('transform', helpers.translate(bbox.width - swatchDimensions.width, 0));
    });

    // Re-stack groups with no padding
    this.base.selectAll('.chart-legend-group').call(helpers.stack({
      direction: this.props.stackDirection,
      origin: 'bottom',
      padding: 0
    }));
    var label = this.base.selectAll('.legend-title').data([legendTitle || '']);
    label.enter().append('text').attr('class', 'legend-title');
    label.text(function (d) {
      return d;
    }).attr('text-anchor', 'middle').attr('transform', function () {
      var legend = chart.base.select('.chart-legend');
      var legend_bbox = legend.node().getBBox();
      var legend_translate = getTranslateParts(legend.attr('transform'));
      var x = legend_translate[0] + legend_bbox.width / 2;
      var y = -50;
      return helpers.translate(x, y);
    });
  }
});
Overlay.extend('Popover', {
  initialize: function initialize(options) {
    var _this5 = this;
    var hiding;
    this.base.classed('chart-popover', true);
    this.on('attach', function () {
      _this5.container.on('mouseenter:state', function (d) {
        clearTimeout(hiding);
        _this5.render(d, options);
        _this5.show();
      });
      _this5.container.on('mouseleave:state', function () {
        hiding = setTimeout(function () {
          return _this5.hide();
        }, 250);
      });
    });
  },
  render: function render(d, options) {
    var _this6 = this;
    var is_small_screen = document.body.clientWidth < 500;
    if (is_small_screen) return;
    var show_on_left = d.coords.left[0] > 450;
    this.position({
      chart: {
        x: show_on_left ? d.coords.left[0] : d.coords.right[0],
        y: show_on_left ? d.coords.left[1] : d.coords.right[1]
      }
    });
    this.title = '';
    if (options.include_overlay_state_title) this.title = d.properties.name || 'Title placeholder';
    this.content = d.value || 'Content placeholder';
    this.placement = show_on_left ? 'left' : 'right';

    // Add bootstrap popover (if needed) and show
    var base = $(this.base);
    if (!this.created) {
      base.popover({
        placement: function placement() {
          return _this6.placement;
        },
        trigger: 'manual',
        container: base,
        html: true,
        content: function content() {
          return _this6.content;
        },
        title: function title() {
          return _this6.title;
        },
        animation: false
      });
      this.created = true;
    }
    base.popover('show');
  },
  //Override default overlay type's style property

  style: property({
    default_value: function default_value() {
      // Bootstrap popover requires absolute positioning of element
      // (rather than css transform, which is the default)

      var styles = {
        position: 'absolute',
        top: this.y() + 'px',
        left: this.x() + 'px',
        opacity: this.hidden() ? 0 : 1
      };
      return helpers.style(styles);
    }
  })
});
Chart.extend('ChoroplethSource', {
  initialize: function initialize() {
    this.layer('source', this.base, {
      dataBind: function dataBind() {
        return this.selectAll('text').data([null]);
      },
      insert: function insert() {
        return this.append('text').attr('text-anchor', 'end').classed('choropleth-source', true);
      },
      events: {
        merge: function merge() {
          var chart = this.chart();
          var x = chart.width() - 90;
          var y = chart.height() + 10;
          this.text(chart.source()).attr('transform', translate(x, y));
        }
      }
    });
  },
  source: property()
});
export function moveToTop(element) {
  if (element && element.parentNode) {
    element.parentNode.appendChild(element);
  }
}
function getTranslateParts(transform) {
  var parts = transform.replace('translate(', '').replace(')', '').split(',').map(function (part) {
    return Number(part.trim());
  });
  return parts;
}