function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { isFunction, assign, compact, find } from 'lodash';
import { stratification_types, getFilename, getChartLabel } from './config';
import config from './config';
import { initial_year_fix } from './config';
import { prevalence_store, deaths_avoided_store, lys_gained_store } from './store';
import getEndYearData from './utils/get-end-year-data';

// TODO NEXT: figure out ALL view for prevalence. Data error, perhaps when calculating the y0 values (which won't be necessary)
// Whow Male/Female/Both baseline + policy + area plot

//
// Prevalence
//

export function prevalenceQuery(state, options) {
  var store = prevalence_store;
  var dir = 'results';
  var types = ['baseline', 'policy'];
  return cohortQuery(state, assign({
    store: store,
    dir: dir,
    types: types,
    series: function series(gender, cohort, type) {
      return {
        meta: {
          result_type: "".concat(gender, "_").concat(type),
          cohort: cohort
        },
        exclude_from_legend: type === 'policy'
      };
    },
    age_group: state.age_group
  }, options));
}

//
// Deaths Avoided
//

export function deathsAvoidedQuery(state, options) {
  var store = deaths_avoided_store;
  var dir = 'deaths';
  var types = ['deaths_avoided'];
  return cohortQuery(state, assign({
    store: store,
    dir: dir,
    types: types,
    series: function series(gender, cohort, type) {
      return {
        meta: {
          result_type: "deaths_avoided_".concat(gender),
          cohort: cohort
        }
      };
    }
  }, options));
}

//
// Life-years Gained
//

export function lysGainedQuery(state, options) {
  var store = lys_gained_store;
  var dir = 'lyg';
  var types = ['lys_gained'];
  return cohortQuery(state, assign({
    store: store,
    dir: dir,
    types: types,
    series: function series(gender, cohort, type) {
      return {
        meta: {
          result_type: "lys_gained_".concat(gender),
          cohort: cohort
        }
      };
    }
  }, options));
}

//
// General Queries
//

function cohortQuery(state, options) {
  var policy_year = state.policy_year,
    horizon = state.horizon,
    age_period = state.age_period;
  var store = options.store,
    dir = options.dir,
    types = options.types,
    getSeries = options.series,
    result_type = options.result_type,
    age_group = options.age_group,
    _options$groupBy = options.groupBy,
    groupBy = _options$groupBy === void 0 ? ['result_type', 'cohort'] : _options$groupBy;
  var _getCohortsAndGenders = getCohortsAndGenders(state, options),
    cohorts = _getCohortsAndGenders.cohorts,
    genders = _getCohortsAndGenders.genders;
  var single = state.stratification !== 'all' && state.stratification !== 'all-by-age';
  var series = expandMap(genders, cohorts, types, function (gender, cohort, type) {
    var classes = [single && "gender-".concat(gender), "cohort-".concat(cohort), single && 'cohort-single', "type-".concat(type)];
    return assign({
      name: single ? getChartLabel(gender) : String(cohort),
      key: "".concat(gender, "-").concat(single ? 'single' : cohort, "-").concat(type),
      "class": compact(classes).join(' ')
    }, getSeries(gender, cohort, type));
  });
  var filter = _objectSpread({
    cohort: {
      $in: cohorts
    },
    year: {
      $gte: policy_year - initial_year_fix,
      $lte: horizon
    }
  }, state.policy !== 't21' ? {
    policy_year: policy_year
  } : {}, {
    y: {
      $ne: null
    }
  });
  if (age_group && !state.by_cohort) {
    filter.age = age_group;
  }
  return store.query({
    groupBy: groupBy,
    series: series,
    from: [getFilename(state, dir)],
    filter: filter,
    end_year: horizon,
    xKey: age_period
  }).then(function (data) {
    var filter = _defineProperty({}, age_period, age_period === 'age' ? config.MAX_AGE : horizon);
    return getEndYearData(data, filter).values;
  });
}

//
// Utilities
//

function getCohortsAndGenders(state, options) {
  var element = find(options.stratification_types || stratification_types, function (type) {
    return type.value == state.stratification;
  });
  var cohorts = element && element.cohorts || ['ALL'];
  var genders = state.stratification !== 'all' && state.stratification !== 'all-by-age' ? ['both', 'males', 'females'] : ['both'];
  return {
    cohorts: cohorts,
    genders: genders
  };
}
function expandMap(genders, cohorts, types, iterator) {
  if (!cohorts.length) {
    cohorts = ['none'];
  }
  if (isFunction(types)) {
    iterator = types;
    types = ['none'];
  }
  var results = [];
  genders.forEach(function (gender) {
    cohorts.forEach(function (cohort) {
      types.forEach(function (type) {
        results.push(iterator(gender, cohort, type));
      });
    });
  });
  return results;
}