import { assign, find } from 'lodash';
import config, { format, domains, t21_jurisdictions } from '../../config';
import createSmallChart from '../../charts/small-chart';
import createLineChart from '../../charts/line-chart';
import App from '../../app';
import { getDefaultPrevalence, getDefaultPrevalenceDetails, getDefaultDeathsAvoided, getDefaultDeathsAvoidedDetails, getDefaultLysGained, getDefaultLysGainedDetails } from '../policy-charts';
var progress_bar = App.template('progress-bar-stacked-overlay');
export var choropleth = {
  title: 'Policy driving T21 health benefits',
  source: '',
  domain: [0, 1],
  overlayText: function overlayText(d, state, stateFill, labelColor) {
    var coverage_pretext;
    if (state.id === 'US') {
      coverage_pretext = '';
    } else if (d.t21_state > 0) {
      coverage_pretext = "".concat(d.name, " implemented T21 in <strong>").concat(d.t21_state_year, "</strong>.");
    } else {
      coverage_pretext = "".concat(d.name, " has not yet implemented T21.");
    }
    var coverage_text = getCoverageText(d, state.id);

    // Temporarily removing progress bars as per client request, but keeping it here for future reference
    //   const progress = (suffix) => {
    //   return progress_bar(t21_jurisdictions.map(
    //     (jurisdiction) => {
    //       let prefix = 't21_' + jurisdiction.value;
    //       const key = `${prefix}${suffix ? suffix :''}`;
    //       const value = Math.round(d[key]*100);
    //       return {
    //         percent: value, 
    //         percent_text: `${value}%`,
    //         fill: jurisdiction['color'],
    //         striped: suffix ? true : false,
    //         hide: value == 0,
    //       };
    //     })
    //   )
    // }
    // Population coverage<br>
    // ${progress()}
    // Contribution to mortality reduction<br>
    // ${progress('_contr')}
    return "<div class=\"hint\">\n        <span class=\"coverage-pretext\">".concat(coverage_pretext, "</span>\n        <span class=\"coverage-text\">").concat(coverage_text, "</span>\n      </div>");
  },
  fillValue: function fillValue(d) {
    return t21_jurisdictions.map(function (jurisdiction, i) {
      return {
        value: d['t21_' + jurisdiction.value],
        fill: jurisdiction.color
      };
    }).reduce(function (max, current) {
      return current.value > max.value ? current : max;
    }).fill;
  },
  legend_format: format.percent0,
  can_click_states: true,
  include_overlay_state_title: true,
  // Override the legend colors to match the jurisdictions rather than the standard quantize colors
  quantize_override: t21_jurisdictions.map(function (jurisdiction, i) {
    return {
      type: 'Choropleth',
      key: i,
      text: jurisdiction.label,
      fill: jurisdiction.color
    };
  })
};
export var prevalence = {
  overview: function overview(choices) {
    var defaults = getDefaultPrevalence(choices);
    var yScale = {
      domain: domains[choices.us_state].t21.prevalence[choices.mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    var defaults = getDefaultPrevalence(choices);
    var details = getDefaultPrevalenceDetails(choices);
    var yScale = {
      domain: domains[choices.us_state].t21.prevalence[choices.mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
export var prevalence_reduction = {
  overview: function overview(choices) {
    choices = assign({}, choices, {
      mode: 'reduction'
    });
    var defaults = getDefaultPrevalence(choices);
    var yScale = {
      domain: domains[choices.us_state].t21.prevalence[choices.mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    choices = assign({}, choices, {
      mode: 'reduction'
    });
    var defaults = getDefaultPrevalence(choices);
    var details = getDefaultPrevalenceDetails(choices);
    var yScale = {
      domain: domains[choices.us_state].t21.prevalence[choices.mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
export var deaths_avoided = {
  overview: function overview(choices) {
    var defaults = getDefaultDeathsAvoided(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].t21.deaths_avoided[mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    var defaults = getDefaultDeathsAvoided(choices);
    var details = getDefaultDeathsAvoidedDetails(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].t21.deaths_avoided[mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
export var lys_gained = {
  overview: function overview(choices) {
    var defaults = getDefaultLysGained(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].t21.lys_gained[mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    var defaults = getDefaultLysGained(choices);
    var details = getDefaultLysGainedDetails(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].t21.lys_gained[mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
// TODO T21
function getSubtitle(choices) {
  var mla = find(config.mla_ages, {
    value: choices.mla
  });
  if (mla) mla = mla.label;
  var selectedOption = config.t21_coverage_authority_options.find(function (option) {
    return option.value === choices.t21_coverage_authority;
  });
  var label = selectedOption ? selectedOption.label : '';
  return "".concat(label, " jurisdictions, Smoking Initiation impact=").concat(choices.t21_initiation_impact, "%");
}
function getCoverageText(d, us_state_id) {
  switch (us_state_id) {
    case 'US':
      // US
      return "State-level T21 laws are associated with the largest mortality reductions. Federal T21 likely played a key role in encouraging more states to adopt their own T21 laws.";
    case 'MN':
      // high local coverage, specific states listed
      return "Widespread local T21 laws, implemented before state and federal laws, are associated with the largest mortality reductions.";
    case 'MA': // MA || NY
    case 'NY':
      // high local coverage, specific states listed
      return "Widespread local T21 laws, implemented before state and federal laws, are associated with the largest mortality reductions. Early adoption of state and local T21 laws is associated with greater relative mortality reductions compared to other states.";
    default:
      if (d.t21_state === 0 && d.t21_local === 0) {
        // no state or local law (eg NC, SC)
        return "Without state or local T21 laws, potential health benefits rely solely on strong enforcement of the federal law.";
      } else if (d.t21_state === 0 && d.t21_local > 0) {
        // no state law, but some local (eg AK)
        return "Without a state-level T21 law, potential health benefits rely on local laws and strong enforcement of the federal law.";
      } else if (d.t21_state_year < 2020) {
        // state law before federal <2020 (eg OR, HI, CA)
        return "This state implemented T21 before the federal law. Therefore, the federal law is not associated with increased mortality reductions.";
      } else if (d.t21_state_year >= 2020) {
        // state law after federal >= 2020 (eg CO)
        return "This state implemented T21 after the federal law. However, T21 enforcement is generally stronger at the state level, so the state T21 is associated with greater mortality reductions.";
      }
  }
}