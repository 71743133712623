window["SHGPOL"]["templates"]["explorer-component"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "t21 ";
},"3":function(container,depth0,helpers,partials,data) {
    return " active";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"region")||(depth0 && lookupProperty(depth0,"region"))||alias2).call(alias1,{"name":"region","hash":{"class":"explorer-nav-tab","binding":(lookupProperty(helpers,"bound")||(depth0 && lookupProperty(depth0,"bound"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"model") : depth0),"us_state",{"name":"bound","hash":{},"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":44}}})},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":2},"end":{"line":19,"column":13}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button\n      class=\"explorer-nav-link mod-overview"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"eq")||(depth0 && lookupProperty(depth0,"eq"))||alias2).call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"exploring",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":14,"column":53},"end":{"line":14,"column":76}}}),"coverage",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":14,"column":49},"end":{"line":14,"column":88}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":43},"end":{"line":14,"column":104}}})) != null ? stack1 : "")
    + "\"\n      role=\"tab\"\n      aria-controls=\"explorer-coverage\">\n      Coverage\n    </button>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button\n      class=\"explorer-nav-link"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"eq")||(depth0 && lookupProperty(depth0,"eq"))||alias2).call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"exploring",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":24,"column":40},"end":{"line":24,"column":63}}}),"prevalence",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":24,"column":36},"end":{"line":24,"column":77}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":30},"end":{"line":24,"column":93}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"prevalence") : depth0)) != null ? lookupProperty(stack1,"explore_is_NA") : stack1),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":24,"column":99},"end":{"line":24,"column":129}}}),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":93},"end":{"line":24,"column":144}}})) != null ? stack1 : "")
    + "\"\n      role=\"tab\"\n      aria-controls=\"explorer-prevalence\"\n      title=\"Prevalence: "
    + alias3((lookupProperty(helpers,"explorer-format")||(depth0 && lookupProperty(depth0,"explorer-format"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"prevalence") : depth0)) != null ? lookupProperty(stack1,"explore_value") : stack1),"percent1",{"name":"explorer-format","hash":{},"data":data,"loc":{"start":{"line":27,"column":25},"end":{"line":27,"column":80}}}))
    + "\">\n      <span class=\""
    + alias3((lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"value_classes") : depth0)) != null ? lookupProperty(stack1,"prevalence") : stack1),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":28,"column":19},"end":{"line":28,"column":51}}}))
    + "\">\n        "
    + alias3((lookupProperty(helpers,"explorer-format")||(depth0 && lookupProperty(depth0,"explorer-format"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"prevalence") : depth0)) != null ? lookupProperty(stack1,"explore_value") : stack1),"percent1",{"name":"explorer-format","hash":{},"data":data,"loc":{"start":{"line":29,"column":8},"end":{"line":29,"column":63}}}))
    + "\n      </span>\n      <span class=\"explorer-nav-title\">Prevalence</span>\n    </button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return " is-NA";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button\n      class=\"explorer-nav-link"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"eq")||(depth0 && lookupProperty(depth0,"eq"))||alias2).call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"exploring",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":36,"column":40},"end":{"line":36,"column":63}}}),"prevalence_reduction",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":36,"column":36},"end":{"line":36,"column":87}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":30},"end":{"line":36,"column":103}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"prevalence_reduction") : depth0)) != null ? lookupProperty(stack1,"explore_is_NA") : stack1),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":36,"column":109},"end":{"line":36,"column":149}}}),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":103},"end":{"line":36,"column":164}}})) != null ? stack1 : "")
    + "\"\n      role=\"tab\"\n      aria-controls=\"explorer-prevalence_reduction\"\n      title=\"Prevalence Reduction: "
    + alias3((lookupProperty(helpers,"explorer-format")||(depth0 && lookupProperty(depth0,"explorer-format"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"prevalence_reduction") : depth0)) != null ? lookupProperty(stack1,"explore_value") : stack1),"pp1",{"name":"explorer-format","hash":{},"data":data,"loc":{"start":{"line":39,"column":35},"end":{"line":39,"column":95}}}))
    + "\">\n      <span class=\""
    + alias3((lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"value_classes") : depth0)) != null ? lookupProperty(stack1,"prevalence_reduction") : stack1),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":40,"column":19},"end":{"line":40,"column":61}}}))
    + "\">\n        "
    + alias3((lookupProperty(helpers,"explorer-format")||(depth0 && lookupProperty(depth0,"explorer-format"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"prevalence_reduction") : depth0)) != null ? lookupProperty(stack1,"explore_value") : stack1),"pp1",{"name":"explorer-format","hash":{},"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":41,"column":68}}}))
    + "<sup>1</sup>\n      </span>\n      <span class=\"explorer-nav-title\">Prevalence Reduction</span>\n    </button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button\n      class=\"explorer-nav-link"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"eq")||(depth0 && lookupProperty(depth0,"eq"))||alias2).call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"exploring",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":48,"column":40},"end":{"line":48,"column":63}}}),"deaths_avoided",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":48,"column":36},"end":{"line":48,"column":81}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":30},"end":{"line":48,"column":97}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"deaths_avoided") : depth0)) != null ? lookupProperty(stack1,"explore_is_NA") : stack1),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":48,"column":103},"end":{"line":48,"column":137}}}),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":97},"end":{"line":48,"column":152}}})) != null ? stack1 : "")
    + "\"\n      role=\"tab\"\n      aria-controls=\"explorer-deaths_avoided\"\n      title=\"Deaths Avoided: "
    + alias3((lookupProperty(helpers,"explorer-format")||(depth0 && lookupProperty(depth0,"explorer-format"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"deaths_avoided") : depth0)) != null ? lookupProperty(stack1,"explore_value") : stack1),"si3",{"name":"explorer-format","hash":{},"data":data,"loc":{"start":{"line":51,"column":29},"end":{"line":51,"column":83}}}))
    + "\">\n      <span class=\""
    + alias3((lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"value_classes") : depth0)) != null ? lookupProperty(stack1,"deaths_avoided") : stack1),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":52,"column":19},"end":{"line":52,"column":55}}}))
    + "\">\n        "
    + alias3((lookupProperty(helpers,"explorer-format")||(depth0 && lookupProperty(depth0,"explorer-format"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"deaths_avoided") : depth0)) != null ? lookupProperty(stack1,"explore_value") : stack1),"si3",{"name":"explorer-format","hash":{},"data":data,"loc":{"start":{"line":53,"column":8},"end":{"line":53,"column":62}}}))
    + "\n      </span>\n      <span class=\"explorer-nav-title\">Deaths Avoided</span>\n    </button>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button\n      class=\"explorer-nav-link"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"eq")||(depth0 && lookupProperty(depth0,"eq"))||alias2).call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"exploring",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":60,"column":40},"end":{"line":60,"column":63}}}),"lys_gained",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":60,"column":36},"end":{"line":60,"column":77}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":30},"end":{"line":60,"column":93}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"lys_gained") : depth0)) != null ? lookupProperty(stack1,"explore_is_NA") : stack1),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":60,"column":99},"end":{"line":60,"column":129}}}),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":93},"end":{"line":60,"column":144}}})) != null ? stack1 : "")
    + "\"\n      role=\"tab\"\n      aria-controls=\"explorer-lys_gained\"\n      title=\"Life-Years Gained: "
    + alias3((lookupProperty(helpers,"explorer-format")||(depth0 && lookupProperty(depth0,"explorer-format"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"lys_gained") : depth0)) != null ? lookupProperty(stack1,"explore_value") : stack1),"si3",{"name":"explorer-format","hash":{},"data":data,"loc":{"start":{"line":63,"column":32},"end":{"line":63,"column":82}}}))
    + "\">\n      <span class=\""
    + alias3((lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"value_classes") : depth0)) != null ? lookupProperty(stack1,"lys_gained") : stack1),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":64,"column":19},"end":{"line":64,"column":51}}}))
    + "\">\n        "
    + alias3((lookupProperty(helpers,"explorer-format")||(depth0 && lookupProperty(depth0,"explorer-format"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"lys_gained") : depth0)) != null ? lookupProperty(stack1,"explore_value") : stack1),"si3",{"name":"explorer-format","hash":{},"data":data,"loc":{"start":{"line":65,"column":8},"end":{"line":65,"column":58}}}))
    + "\n      </span>\n      <span class=\"explorer-nav-title\">Life-Years Gained</span>\n    </button>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"explorer-no-data"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||container.hooks.helperMissing).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"deaths_avoided") : depth0)) != null ? lookupProperty(stack1,"explore_is_NA") : stack1),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":72,"column":36},"end":{"line":72,"column":70}}}),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":30},"end":{"line":72,"column":85}}})) != null ? stack1 : "")
    + "\">\n    <span class=\"badge\">Not available for this age group</span>\n  </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <h3>Tobacco 21 policy coverage</h3>\n      <img src=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"data_folder") || (depth0 != null ? lookupProperty(depth0,"data_folder") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"data_folder","hash":{},"data":data,"loc":{"start":{"line":145,"column":16},"end":{"line":145,"column":31}}}) : helper)))
    + "/t21-coverage/"
    + alias3((lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"us_state",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":145,"column":45},"end":{"line":145,"column":69}}}))
    + ".svg\" alt=\"Coverage\" style=\"width: 100%;\"/>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return " active ";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"warnings") : depth0)) != null ? lookupProperty(stack1,"flatline") : stack1),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":166,"column":10},"end":{"line":166,"column":33}}}),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":166,"column":4},"end":{"line":177,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"warnings") : depth0)) != null ? lookupProperty(stack1,"no_data") : stack1),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":178,"column":10},"end":{"line":178,"column":32}}}),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":178,"column":4},"end":{"line":189,"column":11}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"flatline-overlay-bg\">\n        <div class=\"flatline-overlay panel panel-primary\">\n          <div class=\"panel-heading\">\n            <h3 class=\"panel-title\">Proposed policy would have no or little effect</h3>\n          </div>\n          <div class=\"panel-body\">\n            "
    + ((stack1 = (lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"warnings") : depth0)) != null ? lookupProperty(stack1,"flatline") : stack1),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":173,"column":12},"end":{"line":173,"column":39}}})) != null ? stack1 : "")
    + "\n          </div>\n        </div>\n      </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"flatline-overlay-bg\">\n        <div class=\"flatline-overlay panel panel-danger\">\n          <div class=\"panel-heading\">\n            <h3 class=\"panel-title\">Problem downloading the data for this policy</h3>\n          </div>\n          <div class=\"panel-body\">\n            Unfortunately, there was a problem downloading the data files for this policy.\n          </div>\n        </div>\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<nav class=\"explorer-nav "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"eq")||(depth0 && lookupProperty(depth0,"eq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"policy") : depth0),"t21",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":1,"column":31},"end":{"line":1,"column":48}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":25},"end":{"line":1,"column":61}}})) != null ? stack1 : "")
    + "row\" role=\"tablist\">\n  <div class=\"explorer-nav-tab\">\n    <button\n      class=\"explorer-nav-link mod-overview"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"eq")||(depth0 && lookupProperty(depth0,"eq"))||alias2).call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"exploring",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":4,"column":53},"end":{"line":4,"column":76}}}),"overview",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":4,"column":49},"end":{"line":4,"column":88}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":43},"end":{"line":4,"column":104}}})) != null ? stack1 : "")
    + "\"\n      role=\"tab\"\n      aria-controls=\"explorer-overview\">\n      Overview\n    </button>\n  </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"eq")||(depth0 && lookupProperty(depth0,"eq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"policy") : depth0),"t21",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":11,"column":25}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":2},"end":{"line":20,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"region")||(depth0 && lookupProperty(depth0,"region"))||alias2).call(alias1,{"name":"region","hash":{"class":"explorer-nav-tab","bindings":(lookupProperty(helpers,"array")||(depth0 && lookupProperty(depth0,"array"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"prevalence") : depth0)) != null ? lookupProperty(stack1,"explore_is_NA") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"prevalence") : depth0)) != null ? lookupProperty(stack1,"explore_value") : stack1),{"name":"array","hash":{},"data":data,"loc":{"start":{"line":22,"column":21},"end":{"line":22,"column":78}}})},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":2},"end":{"line":33,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"region")||(depth0 && lookupProperty(depth0,"region"))||alias2).call(alias1,{"name":"region","hash":{"class":"explorer-nav-tab","bindings":(lookupProperty(helpers,"array")||(depth0 && lookupProperty(depth0,"array"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"prevalence_reduction") : depth0)) != null ? lookupProperty(stack1,"explore_is_NA") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"prevalence_reduction") : depth0)) != null ? lookupProperty(stack1,"explore_value") : stack1),{"name":"array","hash":{},"data":data,"loc":{"start":{"line":34,"column":21},"end":{"line":34,"column":98}}})},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":2},"end":{"line":45,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"region")||(depth0 && lookupProperty(depth0,"region"))||alias2).call(alias1,{"name":"region","hash":{"class":"explorer-nav-tab","bindings":(lookupProperty(helpers,"array")||(depth0 && lookupProperty(depth0,"array"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"deaths_avoided") : depth0)) != null ? lookupProperty(stack1,"explore_is_NA") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"deaths_avoided") : depth0)) != null ? lookupProperty(stack1,"explore_value") : stack1),{"name":"array","hash":{},"data":data,"loc":{"start":{"line":46,"column":21},"end":{"line":46,"column":86}}})},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":2},"end":{"line":57,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"region")||(depth0 && lookupProperty(depth0,"region"))||alias2).call(alias1,{"name":"region","hash":{"class":"explorer-nav-tab","bindings":(lookupProperty(helpers,"array")||(depth0 && lookupProperty(depth0,"array"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"lys_gained") : depth0)) != null ? lookupProperty(stack1,"explore_is_NA") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"lys_gained") : depth0)) != null ? lookupProperty(stack1,"explore_value") : stack1),{"name":"array","hash":{},"data":data,"loc":{"start":{"line":58,"column":21},"end":{"line":58,"column":78}}})},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":2},"end":{"line":69,"column":13}}})) != null ? stack1 : "")
    + "  </li>\n"
    + ((stack1 = (lookupProperty(helpers,"region")||(depth0 && lookupProperty(depth0,"region"))||alias2).call(alias1,{"name":"region","hash":{"binding":((stack1 = (depth0 != null ? lookupProperty(depth0,"deaths_avoided") : depth0)) != null ? lookupProperty(stack1,"explore_is_NA") : stack1)},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":2},"end":{"line":75,"column":13}}})) != null ? stack1 : "")
    + "</nav>\n\n<div class=\"explorer-tabs tab-content\">\n  <div class=\"tab-pane"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"eq")||(depth0 && lookupProperty(depth0,"eq"))||alias2).call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"exploring",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":79,"column":32},"end":{"line":79,"column":55}}}),"overview",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":79,"column":28},"end":{"line":79,"column":67}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":22},"end":{"line":79,"column":83}}})) != null ? stack1 : "")
    + " active\" role=\"tabpanel\" id=\"explorer-overview\">\n    <div class=\"row explorer-overview\">\n      <div class=\"explorer-overview-section col-md-6\" role=\"button\" data-id=\"prevalence\">\n        "
    + alias3((lookupProperty(helpers,"chart")||(depth0 && lookupProperty(depth0,"chart"))||alias2).call(alias1,"overview_prevalence_chart",{"name":"chart","hash":{"ref":(depth0 != null ? lookupProperty(depth0,"attachChart") : depth0),"active":(depth0 != null ? lookupProperty(depth0,"isOverview") : depth0),"class":"tcp-chart mod-small mod-prevalence","margins":(lookupProperty(helpers,"object")||(depth0 && lookupProperty(depth0,"object"))||alias2).call(alias1,{"name":"object","hash":{"right":20},"data":data,"loc":{"start":{"line":87,"column":18},"end":{"line":87,"column":35}}}),"height":220,"width":350,"data":((stack1 = (depth0 != null ? lookupProperty(depth0,"prevalence") : depth0)) != null ? lookupProperty(stack1,"explore_data") : stack1),"config":((stack1 = (depth0 != null ? lookupProperty(depth0,"prevalence") : depth0)) != null ? lookupProperty(stack1,"overview_config") : stack1)},"data":data,"loc":{"start":{"line":82,"column":8},"end":{"line":91,"column":10}}}))
    + "\n      </div>\n\n      <div class=\"explorer-overview-section col-md-6\" role=\"button\" data-id=\"prevalence_reduction\">\n        "
    + alias3((lookupProperty(helpers,"chart")||(depth0 && lookupProperty(depth0,"chart"))||alias2).call(alias1,"overview_prevalence_reduction_chart",{"name":"chart","hash":{"ref":(depth0 != null ? lookupProperty(depth0,"attachChart") : depth0),"active":(depth0 != null ? lookupProperty(depth0,"isOverview") : depth0),"class":"tcp-chart mod-small mod-prevalence-reduction","margins":(lookupProperty(helpers,"object")||(depth0 && lookupProperty(depth0,"object"))||alias2).call(alias1,{"name":"object","hash":{"right":20},"data":data,"loc":{"start":{"line":100,"column":18},"end":{"line":100,"column":35}}}),"height":220,"width":350,"data":((stack1 = (depth0 != null ? lookupProperty(depth0,"prevalence_reduction") : depth0)) != null ? lookupProperty(stack1,"explore_data") : stack1),"config":((stack1 = (depth0 != null ? lookupProperty(depth0,"prevalence_reduction") : depth0)) != null ? lookupProperty(stack1,"overview_config") : stack1)},"data":data,"loc":{"start":{"line":95,"column":8},"end":{"line":104,"column":10}}}))
    + "\n      <div class=\"row text-center\" style=\"font-size: 75%;\">\n        <sup>1</sup> pp = Percentage Points\n      </div>\n      </div>\n    </div>\n\n    <div class=\"row explorer-overview\">\n      <div class=\"explorer-overview-section col-md-6\" role=\"button\" data-id=\"deaths_avoided\">\n        "
    + alias3((lookupProperty(helpers,"chart")||(depth0 && lookupProperty(depth0,"chart"))||alias2).call(alias1,"deaths_avoided_chart",{"name":"chart","hash":{"ref":(depth0 != null ? lookupProperty(depth0,"attachChart") : depth0),"on":(lookupProperty(helpers,"object")||(depth0 && lookupProperty(depth0,"object"))||alias2).call(alias1,{"name":"object","hash":{"reset-na-parameters":(depth0 != null ? lookupProperty(depth0,"onResetNAParameters") : depth0)},"data":data,"loc":{"start":{"line":121,"column":13},"end":{"line":121,"column":61}}}),"active":(depth0 != null ? lookupProperty(depth0,"isOverview") : depth0),"class":"tcp-chart mod-small mod-deaths-avoided","margins":(lookupProperty(helpers,"object")||(depth0 && lookupProperty(depth0,"object"))||alias2).call(alias1,{"name":"object","hash":{"right":20},"data":data,"loc":{"start":{"line":118,"column":18},"end":{"line":118,"column":35}}}),"height":220,"width":350,"data":((stack1 = (depth0 != null ? lookupProperty(depth0,"deaths_avoided") : depth0)) != null ? lookupProperty(stack1,"explore_data") : stack1),"config":((stack1 = (depth0 != null ? lookupProperty(depth0,"deaths_avoided") : depth0)) != null ? lookupProperty(stack1,"overview_config") : stack1)},"data":data,"loc":{"start":{"line":113,"column":8},"end":{"line":123,"column":10}}}))
    + "\n      </div>\n\n      <div class=\"explorer-overview-section col-md-6\" role=\"button\" data-id=\"lys_gained\">\n        "
    + alias3((lookupProperty(helpers,"chart")||(depth0 && lookupProperty(depth0,"chart"))||alias2).call(alias1,"lys_gained_chart",{"name":"chart","hash":{"ref":(depth0 != null ? lookupProperty(depth0,"attachChart") : depth0),"on":(lookupProperty(helpers,"object")||(depth0 && lookupProperty(depth0,"object"))||alias2).call(alias1,{"name":"object","hash":{"reset-na-parameters":(depth0 != null ? lookupProperty(depth0,"onResetNAParameters") : depth0)},"data":data,"loc":{"start":{"line":135,"column":13},"end":{"line":135,"column":61}}}),"active":(depth0 != null ? lookupProperty(depth0,"isOverview") : depth0),"class":"tcp-chart mod-small mod-lys-gained","margins":(lookupProperty(helpers,"object")||(depth0 && lookupProperty(depth0,"object"))||alias2).call(alias1,{"name":"object","hash":{"right":20},"data":data,"loc":{"start":{"line":132,"column":18},"end":{"line":132,"column":35}}}),"height":220,"width":350,"data":((stack1 = (depth0 != null ? lookupProperty(depth0,"lys_gained") : depth0)) != null ? lookupProperty(stack1,"explore_data") : stack1),"config":((stack1 = (depth0 != null ? lookupProperty(depth0,"lys_gained") : depth0)) != null ? lookupProperty(stack1,"overview_config") : stack1)},"data":data,"loc":{"start":{"line":127,"column":8},"end":{"line":137,"column":10}}}))
    + "\n      </div>\n    </div>\n  </div>\n\n  <div class=\"tab-pane"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"eq")||(depth0 && lookupProperty(depth0,"eq"))||alias2).call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"coverage",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":142,"column":32},"end":{"line":142,"column":54}}}),"coverage",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":142,"column":28},"end":{"line":142,"column":66}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":142,"column":22},"end":{"line":142,"column":82}}})) != null ? stack1 : "")
    + " text-center\" role=\"tabpanel\" id=\"explorer-coverage\" style=\"padding-left: 5%; padding-right: 5%;\">\n"
    + ((stack1 = (lookupProperty(helpers,"region")||(depth0 && lookupProperty(depth0,"region"))||alias2).call(alias1,{"name":"region","hash":{"binding":(lookupProperty(helpers,"bound")||(depth0 && lookupProperty(depth0,"bound"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"model") : depth0),"us_state",{"name":"bound","hash":{},"data":data,"loc":{"start":{"line":143,"column":22},"end":{"line":143,"column":46}}})},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":143,"column":4},"end":{"line":146,"column":15}}})) != null ? stack1 : "")
    + "  </div>\n\n  <div class=\"tab-pane"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"eq")||(depth0 && lookupProperty(depth0,"eq"))||alias2).call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"exploring",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":149,"column":32},"end":{"line":149,"column":55}}}),"prevalence",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":149,"column":28},"end":{"line":149,"column":69}}}),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":22},"end":{"line":149,"column":86}}})) != null ? stack1 : "")
    + "\" role=\"tabpanel\" id=\"explorer-prevalence\">\n    "
    + alias3((lookupProperty(helpers,"render")||(depth0 && lookupProperty(depth0,"render"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"views") : depth0)) != null ? lookupProperty(stack1,"prevalence") : stack1),{"name":"render","hash":{},"data":data,"loc":{"start":{"line":150,"column":4},"end":{"line":150,"column":31}}}))
    + "\n  </div>\n  <div class=\"tab-pane"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"eq")||(depth0 && lookupProperty(depth0,"eq"))||alias2).call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"exploring",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":152,"column":32},"end":{"line":152,"column":55}}}),"prevalence_reduction",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":152,"column":28},"end":{"line":152,"column":79}}}),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":152,"column":22},"end":{"line":152,"column":96}}})) != null ? stack1 : "")
    + "\" role=\"tabpanel\" id=\"explorer-prevalence_reduction\">\n    "
    + alias3((lookupProperty(helpers,"render")||(depth0 && lookupProperty(depth0,"render"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"views") : depth0)) != null ? lookupProperty(stack1,"prevalence_reduction") : stack1),{"name":"render","hash":{},"data":data,"loc":{"start":{"line":153,"column":4},"end":{"line":153,"column":41}}}))
    + "\n    <div class=\"row text-center\">\n      <sup>1</sup> pp = Percentage Points (baseline smoking prevalence - policy smoking prevalence)\n    </div>\n  </div>\n  <div class=\"tab-pane"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"eq")||(depth0 && lookupProperty(depth0,"eq"))||alias2).call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"exploring",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":158,"column":32},"end":{"line":158,"column":55}}}),"deaths_avoided",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":158,"column":28},"end":{"line":158,"column":73}}}),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":22},"end":{"line":158,"column":90}}})) != null ? stack1 : "")
    + "\" role=\"tabpanel\" id=\"explorer-deaths_avoided\">\n    "
    + alias3((lookupProperty(helpers,"render")||(depth0 && lookupProperty(depth0,"render"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"views") : depth0)) != null ? lookupProperty(stack1,"deaths_avoided") : stack1),{"name":"render","hash":{},"data":data,"loc":{"start":{"line":159,"column":4},"end":{"line":159,"column":35}}}))
    + "\n  </div>\n  <div class=\"tab-pane"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"eq")||(depth0 && lookupProperty(depth0,"eq"))||alias2).call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"exploring",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":161,"column":32},"end":{"line":161,"column":55}}}),"lys_gained",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":161,"column":28},"end":{"line":161,"column":69}}}),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":161,"column":22},"end":{"line":161,"column":86}}})) != null ? stack1 : "")
    + "\" role=\"tabpanel\" id=\"explorer-lys_gained\">\n    "
    + alias3((lookupProperty(helpers,"render")||(depth0 && lookupProperty(depth0,"render"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"views") : depth0)) != null ? lookupProperty(stack1,"lys_gained") : stack1),{"name":"render","hash":{},"data":data,"loc":{"start":{"line":162,"column":4},"end":{"line":162,"column":31}}}))
    + "\n  </div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"region")||(depth0 && lookupProperty(depth0,"region"))||alias2).call(alias1,{"name":"region","hash":{"class":"explorer-overview-warnings","bindings":(lookupProperty(helpers,"array")||(depth0 && lookupProperty(depth0,"array"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"warnings") : depth0)) != null ? lookupProperty(stack1,"flatline") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"warnings") : depth0)) != null ? lookupProperty(stack1,"no_data") : stack1),{"name":"array","hash":{},"data":data,"loc":{"start":{"line":165,"column":21},"end":{"line":165,"column":63}}})},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":165,"column":2},"end":{"line":190,"column":13}}})) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true});;