import { bindAll } from 'lodash';
import { Model } from 'backbone';
import { bound, computed, BoundModel } from 'backbone-component';
import PageView from '../page-view';
import App from '../../app';
import config, { t21_jurisdictions, default_data_folder, default_policy_year, default_t21_horizon } from '../../config';
import * as charts from './charts';
import { bindPrevalence, bindPrevalenceReduction, bindDeathsAvoided, bindLysGained, bindWarnings, withStateDefaults, withEditInitial, withEndValue, withFiltered, withNotify } from '../policy-helpers';
import ExpenditureTourView from '../../views/t21-tour-view';
import { changer } from '../../utils';
var getStateDefaults = function getStateDefaults(us_state) {
  var defaults = config.default_params[us_state];
  return {
    t21_local: defaults.t21_local,
    t21_state: defaults.t21_state,
    t21_federal: defaults.t21_federal,
    t21_local_contr: defaults.t21_local_contr,
    t21_state_contr: defaults.t21_state_contr,
    t21_federal_contr: defaults.t21_federal_contr,
    t21_state_year: defaults.t21_state_year,
    t21_local_label: defaults.t21_local_label,
    t21_state_label: defaults.t21_state_label,
    t21_federal_label: defaults.t21_federal_label,
    t21_local_contr_label: defaults.t21_local_contr_label,
    t21_state_contr_label: defaults.t21_state_contr_label,
    t21_federal_contr_label: defaults.t21_federal_contr_label
  };
};
export default PageView.extend({
  title: 'Tobacco 21 laws',
  template: App.template('t21'),
  defaults: function defaults() {
    return new Model({
      policy: 't21',
      policy_year: default_policy_year,
      us_state: 'US',
      data_folder: default_data_folder,
      stratification: 'combined',
      age_group: '18-99',
      mode: 'absolute',
      horizon: default_t21_horizon,
      chart_scaling: 'dynamic',
      exploring: 'overview',
      filtered: [],
      t21_coverage_authority: 'SL',
      // 'SL' is default for all states
      t21_initiation_impact: 34 // 33.91 is default for all states
    });
  },
  conversions: {
    policy_year: Number,
    t21_coverage_authority: String,
    t21_initiation_impact: Number,
    t21_initial_initiation_impact: Number
  },
  excludeFromQuerystring: ['by_cohort', 'age_period', 'coverage_values', 'contribution_values'],
  initialize: function initialize() {
    var _this = this;
    bindAll(this, 'onResetParameters');
    this.state.set({
      is_touring: false
    });
    this.model.connect({
      by_cohort: computed(this.model, 'stratification', function (stratification) {
        return stratification !== 'combined';
      }),
      age_period: computed(this.model, 'stratification', function (stratification) {
        return stratification === 'all-by-age' ? 'age' : 'year';
      })
    });
    this.stratification_types = [{
      value: 'combined',
      label: 'Population',
      cohorts: ['ALL']
    }, {
      value: '2000',
      label: '2000 birth-cohort',
      cohorts: ['2000']
    }, {
      value: '2010',
      label: '2010 birth-cohort',
      cohorts: ['2010']
    }, {
      value: '2020',
      label: '2020 birth-cohort',
      cohorts: ['2020']
    }, {
      value: 'all',
      label: 'All cohorts',
      cohorts: ['2000', '2010', '2020']
    }, {
      value: 'all-by-age',
      label: 'All cohorts by age',
      cohorts: ['2000', '2010', '2020']
    }];
    withStateDefaults(this, getStateDefaults);
    withEditInitial(this, getStateDefaults);
    withEndValue(this, 2100);
    withFiltered(this);
    withNotify(this, 't21');
    var query_options = {
      stratification_types: this.stratification_types
    };
    this.prevalence = bindPrevalence(this.model, this.state, charts.prevalence, query_options);
    this.prevalence_reduction = bindPrevalenceReduction(this.model, this.state, charts.prevalence_reduction, query_options);
    this.deaths_avoided = bindDeathsAvoided(this.model, this.state, charts.deaths_avoided, query_options);
    this.lys_gained = bindLysGained(this.model, this.state, charts.lys_gained, query_options);
    this.warnings = bindWarnings({
      data: this.prevalence.explore_data,
      flatline: computed(this.model, ['t21_coverage_authority', 't21_initiation_impact'], function (mla_pac19, mla_pac21, mla) {
        var us_state = _this.model.get('us_state');
        var us_state_label = _this.config.us_states[us_state].label;
        var defaults = getStateDefaults(us_state);
        var standard = function standard(reason) {
          return "<p>The parameters you have chosen result in little or no effect on mortality or prevalence.</p>\n          <p><strong>Reason:</strong> ".concat(reason, "</p>");
        };
        //TODO T21 remove references to mla
        var all_defaults_selected = defaults.mla_pac21 == mla_pac21 && defaults.mla_pac19 == mla_pac19 && defaults.mla == mla;

        // TODO review text and logic. Just stubbing this in for initial UAT
        if (defaults.mla_pac21 == 100 && (defaults.mla === 0 || defaults.mla === 1) && all_defaults_selected) {
          return "<p>".concat(us_state_label, " already has a strong MLA policy in place that reduces smoking prevalence and smoking-related deaths. As such, improving MLA coverage further would have little or no effect.</p>\n            <p>To explore further, click the <span class=\"icon-component icon-lock\"><svg><use xlink:href=\"#icon-lock\"></use></svg></span>icons to the left to unlock and change the default values.</p>");
        } else if (mla_pac21 == 100 && (mla == 0 || mla == 1)) {
          return standard("It appears that PAC 21 is 100%, but your proposed policy only increases the MLA to 19 or 21 which would have no effect.");
        } else if (mla_pac19 + mla_pac21 == 100 && mla == 0) {
          return standard("It appears that PAC 19 + PAC 21 = 100%, but your proposed policy only increases the MLA to 19 which would have no effect.");
        }
      })
    });
    this.choropleth = charts.choropleth;
    this.tour = new ExpenditureTourView({
      model: new BoundModel({
        us_state: bound(this.model, 'us_state'),
        showing: bound(this.state, 'is_touring'),
        exploring: bound(this.model, 'exploring')
      })
    });
    this.change = changer();
    this.model.set('coverage_values', this.getCoverageSegments());
    this.model.set('contribution_values', this.getCoverageSegments('_contr'));
    this.listenTo(this.model, 'change:us_state', function () {
      _this.change(function () {
        _this.model.set('coverage_values', _this.getCoverageSegments());
        _this.model.set('contribution_values', _this.getCoverageSegments('_contr'));
      });
    });
  },
  config: config,
  // TODO T21 round to nearest 1 percent (? Is that okay with client?) Otherwise, displaying %<0.5% is hard with the progress bar.
  // CT, TX, GA, AK, OH, MS have rounding issues (rounding results in 101% because of trailing 5)
  // Need to share/reference the same display logic between here and chart.js (for the overview)
  getCoverageSegments: function getCoverageSegments(suffix) {
    var _this2 = this;
    return t21_jurisdictions.map(function (jurisdiction) {
      var prefix = 't21_' + jurisdiction.value;
      var key = "".concat(prefix).concat(suffix ? suffix : '');
      var value = _this2.model.get(key);
      var label = _this2.model.get(key + '_label');
      return {
        percent: Math.round(value),
        // in a bootstrap progress bar, must not have decimals and total must not exceed 100%
        percent_text: "".concat(label, "%"),
        fill: jurisdiction.color,
        hide: value == 0,
        striped: suffix ? true : false,
        title: jurisdiction['label'] + " ".concat(suffix == '_contr' ? "mortality contribution" : "coverage", ": ").concat(label, " %")
      };
    });
  },
  toEditTitle: function toEditTitle(editing) {
    return editing ? 'Click to use default minimum age' : 'Click to change minimum age (advanced)';
  },
  onResetParameters: function onResetParameters() {
    this.model.set('age_group', '18-99', {
      notify: true
    });
  }
});