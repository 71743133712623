import { format as d3_format } from 'd3';
import { find } from 'lodash';
import { default_params, us_states, us_states_array } from './default-params';
import SHGPOL from 'SHGPOL';
export { default as domains } from './domains.json';
var config = SHGPOL.config = {
  default_params: default_params,
  us_states: us_states,
  us_states_array: us_states_array
};
config.date_modified = '12-20-2024'; // TODO: Find out if the date_modified is different for ghw
config.dataset_version = '?' + config.date_modified; // cache breaker for csv

export var initial_year_fix = 1; // Used to add the point at the start of the policy
export var by_age_start_value = 12;
export var by_age_end_value = 99;
config.MAX_AGE = 99;
var decimal0 = d3_format('.0f');
var decimal1 = d3_format(',.1f');
var billion = function billion(x) {
  return decimal1(x / 1e9) + 'B';
};
var million = function million(x) {
  return decimal1(x / 1e6) + 'M';
};
var thousand = function thousand(x) {
  return decimal0(x / 1e3) + 'k';
};
export function abbreviate(x) {
  var value = Math.abs(x);
  return (value >= 0.9995e9 ? billion : value >= 0.9995e6 ? million : thousand)(x);
}
export var format = {
  percent0: function percent0(d) {
    return d === 0 ? '0%' : d3_format(',%')(d);
  },
  percent1: function percent1(d) {
    return d === 0 ? '0%' : d3_format('.1f')(d * 100) + '%';
  },
  percent2: function percent2(d) {
    return d === 0 ? '0%' : d3_format('.2f')(d * 100) + '%';
  },
  percent3: function percent3(d) {
    return d === 0 ? '0%' : d3_format('.3f')(d * 100) + '%';
  },
  pp0: function pp0(d) {
    return d === 0 ? '0 pp' : d3_format('.0f')(d * 100) + ' pp';
  },
  pp1: function pp1(d) {
    return d === 0 ? '0 pp' : d3_format('.1f')(d * 100) + ' pp';
  },
  pp2: function pp2(d) {
    return d === 0 ? '0 pp' : d3_format('.2f')(d * 100) + ' pp';
  },
  pp3: function pp3(d) {
    return d === 0 ? '0 pp' : d3_format('.3f')(d * 100) + ' pp';
  },
  pp2Full: function pp2Full(d) {
    return d3_format('.2f')(d * 100) + ' percentage point';
  },
  thousands: function thousands(d) {
    return d3_format(',')(Math.round(d));
  },
  si0: function si0(d) {
    return d === 0 ? '0' : d3_format('.0s')(d);
  },
  si1: function si1(d) {
    return d === 0 ? '0' : d3_format('.1s')(d);
  },
  si2: function si2(d) {
    return d === 0 ? '0' : d3_format('.2s')(d);
  },
  si3: function si3(d) {
    return d === 0 ? '0' : d3_format('.3s')(d);
  },
  four: d3_format('4d'),
  money: d3_format('$,.2f'),
  ageGroup: function ageGroup(group) {
    return find(config.age_group, {
      value: group
    }).label;
  },
  decimal0: decimal0,
  decimal1: decimal1,
  billion: billion,
  million: million,
  thousand: thousand,
  abbreviate: abbreviate
};

/**
  General parameters
*/
config.policy_year = [{
  value: 2019,
  label: '2019',
  position: 0
}, {
  value: 2020,
  label: '2020',
  position: 25
}, {
  value: 2021,
  label: '2021',
  position: 50
}, {
  value: 2022,
  label: '2022',
  position: 75
}, {
  value: 2023,
  label: '2023',
  position: 100
}];
export var default_policy_year = 2023;
export var default_policy_year_ghw = 2022;
export var default_horizon = 2100;
export var default_t21_horizon = 2100;
config.horizon = [{
  value: 2030,
  label: '2030'
}, {
  value: 2040,
  label: '2040'
}, {
  value: 2050,
  label: '2050'
}, {
  value: 2060,
  label: '2060'
}, {
  value: 2070,
  label: '2070'
}, {
  value: 2080,
  label: '2080'
}, {
  value: 2090,
  label: '2090'
}, {
  value: 2100,
  label: '2100'
}];
config.places = {
  w: 'workplaces',
  r: 'restaurants',
  b: 'bars'
};
export var stratification_types = [{
  value: 'combined',
  label: 'Population',
  cohorts: ['ALL']
}, {
  value: '1970',
  label: '1970 birth-cohort',
  cohorts: ['1970']
}, {
  value: '1980',
  label: '1980 birth-cohort',
  cohorts: ['1980']
}, {
  value: '1990',
  label: '1990 birth-cohort',
  cohorts: ['1990']
}, {
  value: '2000',
  label: '2000 birth-cohort',
  cohorts: ['2000']
}, {
  value: '2010',
  label: '2010 birth-cohort',
  cohorts: ['2010']
}, {
  value: 'all',
  label: 'All cohorts',
  cohorts: ['1970', '1980', '1990', '2000', '2010']
}, {
  value: 'all-by-age',
  label: 'All cohorts by age',
  cohorts: ['1970', '1980', '1990', '2000', '2010']
}];
config.stratification_types = stratification_types;
export var chart_scaling_types = [{
  value: 'dynamic',
  label: 'dynamic'
}, {
  value: 'fixed',
  label: 'state-based'
}];
config.chart_scaling_types = chart_scaling_types;
config.cohorts = [1970, 1980, 1990, 2000, 2010];
config.cohort_length = 99;
config.age_group = [{
  value: '18-99',
  label: '18 to 99 (All ages)'
},
//{value: '12 to 17', label: '12 to 17'},
{
  value: '18-24',
  label: '18 to 24'
}, {
  value: '25-44',
  label: '25 to 44'
}, {
  value: '45-64',
  label: '45 to 64'
}, {
  value: '65p',
  label: '65+'
}];
export var age_group = config.age_group;
config.mode = [{
  value: 'absolute',
  label: 'Smoking prevalence'
}, {
  value: 'reduction',
  label: 'Smoking prevalence reduction'
}];
config.age_period = [{
  value: 'year',
  label: 'Calendar Year'
}, {
  value: 'age',
  label: 'Age'
}];
config.percents = [{
  value: 0,
  label: '0%',
  key: '0.00'
}, {
  value: 25,
  label: '25%',
  key: '0.25'
}, {
  value: 50,
  label: '50%',
  key: '0.50'
}, {
  value: 75,
  label: '75%',
  key: '0.75'
}, {
  value: 100,
  label: '100%',
  key: '1.00'
}];
config.expenditure_percents = [{
  value: 0,
  label: '0%',
  key: '0.00'
}, {
  value: 10,
  label: '10%',
  key: '0.10'
}, {
  value: 20,
  label: '20%',
  key: '0.20'
}, {
  value: 30,
  label: '30%',
  key: '0.30'
}, {
  value: 40,
  label: '40%',
  key: '0.40'
}, {
  value: 50,
  label: '50%',
  key: '0.50'
}, {
  value: 60,
  label: '60%',
  key: '0.60'
}, {
  value: 70,
  label: '70%',
  key: '0.70'
}, {
  value: 80,
  label: '80%',
  key: '0.80'
}, {
  value: 90,
  label: '90%',
  key: '0.90'
}, {
  value: 100,
  label: '100%',
  key: '1.00'
}];
export function getExpenditureLabel(val) {
  if (val <= 0.04) return 'Very low';else if (val <= 0.25) return 'Low';else if (val <= 0.5) return 'Medium';else if (val <= 0.74) return 'High';else return 'Very high';
}
export function getExpenditureLabelByValue(val) {
  var label = find(config.expenditure_percents, {
    value: val
  });
  return format.percent0(label.key);
}
config.ppp_initial_prices = [{
  value: 4.0,
  label: '$4.00',
  key: '4.00'
}, {
  value: 4.5,
  label: '$4.50',
  key: '4.50'
}, {
  value: 5.0,
  label: '$5.00',
  key: '5.00'
}, {
  value: 5.5,
  label: '$5.50',
  key: '5.50'
}, {
  value: 6.0,
  label: '$6.00',
  key: '6.00'
}, {
  value: 6.5,
  label: '$6.50',
  key: '6.50'
}, {
  value: 7.0,
  label: '$7.00',
  key: '7.00'
}, {
  value: 7.5,
  label: '$7.50',
  key: '7.50'
}, {
  value: 8.0,
  label: '$8.00',
  key: '8.00'
}, {
  value: 8.5,
  label: '$8.50',
  key: '8.50'
}, {
  value: 9.0,
  label: '$9.00',
  key: '9.00'
}, {
  value: 9.5,
  label: '$9.50',
  key: '9.50'
}, {
  value: 10.0,
  label: '$10.00',
  key: '10.00'
}, {
  value: 10.5,
  label: '$10.50',
  key: '10.50'
}];
config.ppp_tax_increases = [{
  value: 1.0,
  label: '$1.00',
  key: '1.00'
}, {
  value: 1.5,
  label: '$1.50',
  key: '1.50'
}, {
  value: 2.0,
  label: '$2.00',
  key: '2.00'
}, {
  value: 2.5,
  label: '$2.50',
  key: '2.50'
}, {
  value: 3.0,
  label: '$3.00',
  key: '3.00'
}, {
  value: 3.5,
  label: '$3.50',
  key: '3.50'
}, {
  value: 4.0,
  label: '$4.00',
  key: '4.00'
}, {
  value: 4.5,
  label: '$4.50',
  key: '4.50'
}, {
  value: 5.0,
  label: '$5.00',
  key: '5.00'
}];
config.t21_coverage_authority_options = [{
  value: 'L',
  label: 'Local',
  key: 'L',
  position: 0
}, {
  value: 'SL',
  label: 'State and Local',
  key: 'SL',
  position: 50
}, {
  value: 'FSL',
  label: 'Federal, State, and Local',
  key: 'FSL',
  position: 100
}];

// TODO not sure if the position attributes for T21 are needed
config.t21_initiation_impact_options = [{
  value: 15,
  label: '15%',
  key: '15',
  position: 0
}, {
  value: 34,
  label: '34%',
  key: '34',
  position: 50
}, {
  value: 53,
  label: '53%',
  key: '53',
  position: 100
}];
config.initiation = [{
  value: 0,
  label: '0% (no change)'
}, {
  value: 0.05,
  label: '5% (conservative)'
}, {
  value: 0.1,
  label: '10% (main estimate)'
}, {
  value: 0.15,
  label: '15% (optimistic)'
}];
config.cessation = [{
  value: 0,
  label: '0% (no change)'
}, {
  value: 0.25,
  label: '25% (conservative)'
}, {
  value: 0.5,
  label: '50% (main estimate)'
}, {
  value: 0.75,
  label: '75% (optimistic)'
}];
config.chart_labels = {
  both: 'Persons',
  females: 'Females',
  males: 'Males'
};
export function getChartLabel(key) {
  if (config.chart_labels.hasOwnProperty(key)) {
    return config.chart_labels[key];
  } else return key;
}

/**
  Configuration for getting data filename from/to parameters
*/
if (ENV.hostname == 'localhost') {
  config.data_folder = 'https://tcpdata.cornerstonenw.com/source_data';
} else if (ENV.hostname == 'tobaccopolicyeffects.org' || ENV.hostname == 'www.tobaccopolicyeffects.org') {
  config.data_folder = 'https://data.tobaccopolicyeffects.org/source_data';
} else if (ENV.hostname == 'tcptool-staging.yalepages.org' || ENV.hostname == 'staging.tobaccopolicyeffects.org') {
  config.data_folder = 'https://data.tobaccopolicyeffects.org/source_data';
} else if (ENV.hostname.includes('cornerstonenw.com')) {
  config.data_folder = 'https://tcpdata.cornerstonenw.com/source_data';
}
var data_version = '2.0.1';
config.data_folder = config.data_folder + '/' + data_version;
export var default_data_folder = config.data_folder;
export var t21_jurisdictions = [{
  value: 'local',
  color: '#c7e3d9',
  label: 'Local'
},
// green
{
  value: 'state',
  color: '#c6d9ea',
  label: 'State'
},
// blue
{
  value: 'federal',
  color: '#fae3c3',
  label: 'Federal'
} // orange
];
export function getFilename(state, type) {
  if (state.policy == 'airlaws') return airlawsFilename(state, type);else if (state.policy == 'taxes') return taxesFilename(state, type);else if (state.policy == 'expenditure') return expenditureFilename(state, type);else if (state.policy == 't21') return T21Filename(state, type); //TODO update t21 to T21
  else if (state.policy == 'health') return ghwFilename(state, type);
}
export function airlawsFilename(state, type) {
  var ban_w = state.ban_w ? 1 : 0;
  var ban_r = state.ban_r ? 1 : 0;
  var ban_b = state.ban_b ? 1 : 0;
  var filename = [type, 'w' + ban_w, 'r' + ban_r, 'b' + ban_b, 'w' + getPercent(state.per_w, state.ban_w), 'r' + getPercent(state.per_r, state.ban_r), 'b' + getPercent(state.per_b, state.ban_b)].join('_') + '.csv' + config.dataset_version;
  return getFolder(state, type) + filename;
}
export function taxesFilename(state, type) {
  var initial_price = state.initial_price;
  var tax_increase = state.tax_increase;
  var filename = [type, d3_format('.2f')(initial_price), 't' + d3_format('.2f')(tax_increase)].join('_') + '.csv' + config.dataset_version;
  return getFolder(state, type) + filename;
}
export function expenditureFilename(state, type) {
  var init_val, new_val;

  // TODO refactor, DRY violation
  init_val = state.initial_percent;
  new_val = state.policy_percent; // workaround
  init_val = find(config.expenditure_percents, {
    value: init_val
  }).key;
  new_val = find(config.expenditure_percents, {
    value: new_val
  }).key;
  if (init_val == new_val) {
    // no change in policy, so we use the default no-change dataset
    init_val = '0.00';
    new_val = '0.00';
  }
  var filename = [type, 'initexp' + init_val, 'policyexp' + new_val].join('_') + '.csv' + config.dataset_version;
  return getFolder(state, type) + filename;
}
export function T21Filename(state, type) {
  var coverage_authority_key = find(config.t21_coverage_authority_options, {
    value: state.t21_coverage_authority
  }).key;
  var initiation_impact_key = find(config.t21_initiation_impact_options, {
    value: state.t21_initiation_impact
  }).key;
  var filename = [type,
  // ex. deaths, results, lyg
  initiation_impact_key,
  // ex. 15, 34, 53
  coverage_authority_key // ex. L, LS, LSF
  ].join('_') + '.csv' + config.dataset_version;
  return getFolder(state, type) + filename;
}
export function ghwFilename(state, type) {
  var filename = [type, 'healthwarnings', state.initiation, state.cessation].join('_') + '.csv'; /// TODO: Confirm if dataset_version is different for ghw policy

  return getFolder(state, type) + filename;
}
export function getFolder(state, type) {
  // Note: you can set data_folder in the querystring to override the default:
  // Example: ?data_folder=https://tobaccopolicyeffects.org/source_data_new

  var data_folder = state.data_folder || default_data_folder;
  //TODO: find way to refactor this and getFilename for more simplicity and less DRYness
  if (state.policy == 'expenditure') return "".concat(data_folder, "/").concat(state.us_state, "/tcexp/").concat(type, "/");else if (state.policy == 't21') return "".concat(data_folder, "/").concat(state.us_state, "/t21/").concat(type, "/"); // TODO: update t21 to T21
  else if (state.policy == 'health') return "".concat(data_folder, "/").concat(state.us_state, "/ghw/").concat(type, "/");else return "".concat(data_folder, "/").concat(state.us_state, "/").concat(state.policy, "/").concat(type, "/");
}
export function getPercent(per, ban) {
  // If no ban, then percent applied is not relevant
  if (!ban) {
    return '0.00';
  } else {
    return find(config.percents, {
      value: per
    }).key;
  }
}
export default config;