import TourView from './tour-view';
import { default_policy_year } from '../config';
import { default_params } from '../default-params';
import { format as d3_format } from 'd3';
var format_percent = d3_format('.2p');
var default_WI_t21_state = format_percent(default_params.WI.original.t21_state);
var default_CA_t21_state = format_percent(default_params.CA.original.t21_state);
var default_CA_t21_local = format_percent(default_params.CA.original.t21_local);
export default TourView.extend({
  steps: function steps(model) {
    return [{
      backdrop: true,
      element: '',
      orphan: true,
      title: 'Welcome!',
      content: "<p>Welcome to the guided tour of the Tobacco 21 Laws policy tool. This brief tour will guide you through each step of the tool.</p>\n        <p>Click Next to continue.</p>"
    }, {
      backdrop: true,
      element: '[data-id="t21"] .policy-choropleth-container',
      placement: 'top',
      title: 'Existing policy coverage',
      content: "<p>Prior to the federal law raising the Minimum Age of Legal Access (MLA) to age 21, many states and localities had existing MLA policies. </p><p> The colors in this map represent the policy tier (local, state, federal) with the greatest coverage in each state as of 2024. States where more than 50% of the population is covered by local T21 laws are colored green. \nStates with less than 50% coverage by local T21 are colored blue if they have state-level T21, and orange if they rely on the federal law.\n</p><p>Hover your mouse pointer over the map to see additional info for each state.</p>"
    }, {
      backdrop: true,
      element: '[data-id="t21"] .policy-choropleth-container',
      placement: 'top',
      title: 'Existing policy coverage',
      content: "<p>For example, in the state of California, local T21 laws cover only ".concat(default_CA_t21_local, " of the population, and a state law was passed in 2016. Therefore, the policy tier with the greatest coverage is state-level T21 and the state is colored blue. </p>"),
      onShow: function onShow() {
        model.set({
          us_state: 'CA'
        });
      }
    }, {
      backdrop: true,
      element: '[data-id="t21"] .policy-choropleth-container',
      placement: 'top',
      title: 'Existing policy coverage',
      content: "<p>In contrast, in Wisconsin, there are no local or state-level T21 laws. Therefore, the only policy coverage comes from federal T21 and the state is colored orange. </p>",
      onShow: function onShow() {
        model.set({
          us_state: 'WI'
        });
      },
      onHide: function onHide() {
        model.set({
          us_state: 'US'
        });
      }
    }, {
      backdrop: true,
      element: '[data-id="t21"] .policy-choropleth-container',
      placement: 'top',
      title: 'How to use the tool',
      content: "<p>To determine the potential effects of T21 laws on smoking prevalence and mortality, you start by clicking your mouse on the region in the map. This will set the model parameters to match the current T21 coverage that region.</p>"
    }, {
      element: '[data-id="t21"] #t21-policy-tier',
      placement: 'bottom',
      backdrop: true,
      title: 'Choose the policy tier',
      content: "<p>Select the policy level that you would like to simulate. Your selection will consider real-world policy coverage in your chosen region.</p>"
    }, {
      element: '[data-id="t21"] #t21-mla',
      title: 'Choose policy effect size',
      placement: 'bottom',
      backdrop: true,
      content: "<p>The T21 policy effect estimate is automatically set based on current research findings. However, you can override this to view optimistic and pessimistic scenarios by clicking the lock symbol.</p>"
    }, {
      element: '[data-id="t21"] .explorer-nav',
      title: 'Review your policy',
      placement: 'top',
      backdrop: true,
      content: "<p>A convenient summary of your policy is available below the policy parameters.</p>"
    }, {
      element: '[data-id="t21"] #explorer-overview',
      title: 'Results',
      backdrop: true,
      placement: 'top',
      content: "<p>The values used in the charts are derived from simulations, using the Tobacco Control Policy population model which was developed by the <a href=\"https://cisnet.cancer.gov\" target=\"_blank\">Cancer Intervention Surveillance Network (CISNET)</a>. This model estimates changes in smoking prevalence by simulating the effect of various tobacco control policies on initiation and cessation rates, as documented in existing policy evaluation literature.</p><p>Click on a chart to zoom in and explore an outcome.</p>",
      onShow: function onShow() {
        model.set({
          exploring: 'overview'
        });
      }
    }, {
      backdrop: true,
      element: '',
      orphan: true,
      title: 'Tour complete',
      placement: 'top',
      content: "<p>Thank you for taking the tour of the Tobacco 21 Laws policy tool.</p>\n        <p>You can always start this tour again by clicking the <strong>Guided tour</strong> button at the top of this page.</p>"
    }];
  }
});