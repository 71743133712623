window["SHGPOL"]["templates"]["progress-bar-stacked"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"options",{"name":"get","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":3,"column":10},"end":{"line":3,"column":31}}}),{"name":"each","hash":{},"fn":container.program(2, data, 2, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":3,"column":2},"end":{"line":9,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"hide") : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":4,"column":4},"end":{"line":8,"column":15}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"progress-bar "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"striped") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":81}}})) != null ? stack1 : "")
    + "\" style=\"text-align: center; width: "
    + alias2(alias1(((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"percent") : stack1), depth0))
    + "%; background-color: "
    + alias2(alias1(((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"fill") : stack1), depth0))
    + "; color: "
    + alias2(alias1(((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"color") : stack1), depth0))
    + ";\" title=\""
    + alias2(alias1(((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n        <span>"
    + alias2(alias1(((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"percent_text") : stack1), depth0))
    + "</span>\n      </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "progress-bar-striped";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"progress\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"get")||(depth0 && lookupProperty(depth0,"get"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"props") : depth0),"options",{"name":"get","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":2,"column":6},"end":{"line":2,"column":27}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":2,"column":0},"end":{"line":10,"column":7}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true,"useBlockParams":true});;