import { assign } from 'lodash';
import { format as d3_format, schemeGnBu } from 'd3';
import config, { getExpenditureLabelByValue, format, domains } from '../../config';
import createSmallChart from '../../charts/small-chart';
import createLineChart from '../../charts/line-chart';
import App from '../../app';
import { getDefaultPrevalence, getDefaultPrevalenceDetails, getDefaultDeathsAvoided, getDefaultDeathsAvoidedDetails, getDefaultLysGained, getDefaultLysGainedDetails } from '../policy-charts';
var progress_bar = App.template('progress-bar');
export var choropleth = {
  policy: 'expenditures',
  title: 'Tobacco control expenditures by state (% CDC rec.)',
  subtitle: 'as percent recommended by CDC',
  source: '',
  domain: [0, 1],
  overlayText: function overlayText(d, state, stateFill, labelColor) {
    var percent = Math.round(d.expenditure_percent_cdc * 100);
    var offset = percent < 15;
    var fill = stateFill(state);
    var color = offset ? '#000' : labelColor(state);
    var progress = progress_bar([{
      percent: percent,
      fill: fill,
      color: color,
      offset: offset
    }], {
      allowedProtoMethods: {
        fill: true
      },
      // handlebars requires this 'fill' function to be specified in a white list
      allowedProtoProperties: {} // leaving this here just for reference
    });
    return "Initial expenditures: <strong>".concat(d.expenditure_funding, "M</strong><br/>\n      Recommended by CDC: <strong>").concat(d.expenditure_cdc, "M</strong><br/>\n      Initial expenditures (% of CDC):\n      ").concat(progress, "\n      <div class=\"hint\">\n        <strong>Hint:</strong> Click the state to set the initial expenditure (rounded to nearest 10%) and propose a change to 100%.\n      </div>");
  },
  fillValue: function fillValue(d) {
    return d.expenditure_percent_cdc;
  },
  colors: schemeGnBu[6].slice(1).reverse(),
  legend_lower_label: '0%',
  legend_upper_label: '100%',
  legend_format: d3_format('.1p'),
  can_click_states: true,
  include_overlay_state_title: true
};
export var prevalence = {
  overview: function overview(choices) {
    var defaults = getDefaultPrevalence(choices);
    var yScale = {
      domain: domains[choices.us_state].expenditure.prevalence[choices.mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    var defaults = getDefaultPrevalence(choices);
    var details = getDefaultPrevalenceDetails(choices);
    var yScale = {
      domain: domains[choices.us_state].expenditure.prevalence[choices.mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
export var prevalence_reduction = {
  overview: function overview(choices) {
    choices = assign({}, choices, {
      mode: 'reduction'
    });
    var defaults = getDefaultPrevalence(choices);
    var yScale = {
      domain: domains[choices.us_state].expenditure.prevalence[choices.mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    choices = assign({}, choices, {
      mode: 'reduction'
    });
    var defaults = getDefaultPrevalence(choices);
    var details = getDefaultPrevalenceDetails(choices);
    var yScale = {
      domain: domains[choices.us_state].expenditure.prevalence[choices.mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
export var deaths_avoided = {
  overview: function overview(choices) {
    var defaults = getDefaultDeathsAvoided(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].expenditure.deaths_avoided[mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    var defaults = getDefaultDeathsAvoided(choices);
    var details = getDefaultDeathsAvoidedDetails(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].expenditure.deaths_avoided[mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
export var lys_gained = {
  overview: function overview(choices) {
    var defaults = getDefaultLysGained(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].expenditure.lys_gained[mode]
    };
    return createSmallChart(assign(defaults, {
      yScale: yScale
    }));
  },
  lines: function lines(choices) {
    var defaults = getDefaultLysGained(choices);
    var details = getDefaultLysGainedDetails(choices);
    var mode = choices.stratification !== 'combined' ? 'cohort' : 'all';
    var yScale = {
      domain: domains[choices.us_state].expenditure.lys_gained[mode]
    };
    var subtitle = getSubtitle(choices);
    return createLineChart(assign(defaults, details, {
      yScale: yScale,
      subtitle: subtitle
    }));
  }
};
function getSubtitle(choices) {
  var current_expenditures = getExpenditureLabelByValue(choices.initial_percent);
  var policy_expenditures = getExpenditureLabelByValue(choices.policy_percent);
  return "Initial expenditures: ".concat(current_expenditures, " | Proposed expenditures: ").concat(policy_expenditures);
}