import { scale as d3_scale } from 'd3';
import * as d3c from 'd3-compose';
import { uniqueId } from 'lodash';
var default_format = function default_format(d) {
  return d;
};
export default function createChoroplethChart(options) {
  var title_text = options.title,
    domain = options.domain,
    colors = options.colors,
    _options$legend_title = options.legend_title,
    legendTitle = _options$legend_title === void 0 ? '' : _options$legend_title,
    _options$legend_forma = options.legend_format,
    legend_format = _options$legend_forma === void 0 ? default_format : _options$legend_forma,
    legend_lower_label = options.legend_lower_label,
    legend_upper_label = options.legend_upper_label,
    overlayText = options.overlayText,
    fillValue = options.fillValue,
    source = options.source,
    _options$quantize_ove = options.quantize_override,
    quantize_override = _options$quantize_ove === void 0 ? false : _options$quantize_ove,
    _options$has_legend = options.has_legend,
    has_legend = _options$has_legend === void 0 ? true : _options$has_legend,
    _options$can_hover = options.can_hover,
    can_hover = _options$can_hover === void 0 ? true : _options$can_hover,
    _options$can_click_st = options.can_click_states,
    can_click_states = _options$can_click_st === void 0 ? true : _options$can_click_st,
    _options$include_over = options.include_overlay_state_title,
    include_overlay_state_title = _options$include_over === void 0 ? true : _options$include_over;
  var id = uniqueId('choropleth-');
  var quantize = options.quantize || d3_scale.quantize().domain(domain).range(colors);
  var step = (domain[1] - domain[0]) / colors.length;
  var legend_data;
  if (quantize_override) {
    // Use a more standard legend with boxes for each category (For T21 only)
    legend_data = quantize_override;
  } else {
    // Use a gradient legend (for all policies except T21)
    legend_data = colors.map(function (color, i) {
      var is_first = i == 0;
      var is_last = i == colors.length - 1;
      var text = '';
      if (is_first) {
        var lower = domain[0] + step * i;
        text = legend_lower_label || legend_format(lower);
      } else if (is_last) {
        var upper = domain[0] + step * (i + 1);
        text = legend_upper_label || legend_format(upper);
      }
      return {
        type: 'Choropleth',
        key: i,
        text: text,
        fill: color
      };
    });
  }
  return function (data) {
    if (!data) {
      return [d3c.title({
        text: 'No map data available at this time',
        "class": 'no-data',
        margins: {
          top: 10
        }
      })];
    }

    // Charts
    var charts = [{
      id: 'choropleth',
      type: 'Choropleth',
      data: data.states,
      selected: data.selected,
      getText: overlayText,
      stateFill: function stateFill(d) {
        var id = d.id;
        d = d.override || data.states[id];
        var value = fillValue(d);
        // Either qauantize OR use value directly (orange, green, blue)
        return typeof value === 'string' ? value : quantize(value, id);
      },
      labelColor: function labelColor(d) {
        d = d.override || data.states[d.id];
        var value = fillValue(d);
        var domain = quantize.domain();

        // For 6 values, highlight bottom 2 (5 -> 1)
        var steps = quantize.range().length;
        var contrast_percent = (steps < 6 ? 1 : 2) / steps;
        var contrast_percentile = domain[0] + contrast_percent * (domain[1] - domain[0]);
        var high_contrast = value <= contrast_percentile;
        return high_contrast ? '#fff' : '#000';
      },
      can_hover: can_hover,
      can_click_states: can_click_states
    }, {
      id: 'source',
      type: 'ChoroplethSource',
      source: source
    }, {
      type: 'Popover',
      id: "".concat(id, "-popover"),
      include_overlay_state_title: include_overlay_state_title
    }];
    if (has_legend) {
      charts.push({
        id: 'legend',
        type: quantize_override ? 'InsetLegend' : 'ScaleLegend',
        data: legend_data,
        swatchDimensions: {
          width: 30,
          height: 30
        },
        stackDirection: 'horizontal',
        translation: {
          x: 350,
          y: -45,
          relative_to: 'left-top'
        },
        legendTitle: legendTitle
      });
    }

    // Titles
    var title = d3c.title({
      text: title_text,
      margins: {
        top: 20,
        bottom: 50,
        left: 0,
        right: 0
      }
    });

    // Layout
    return [title, [d3c.layered(charts)]];
  };
}