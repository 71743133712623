import { sortBy, map, keys, extend, mapValues } from 'lodash';
import original_params from './original-params.json';
export { original_params };
export function customRound(val) {
  var interval = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.25;
  // Rounds to nearest interval (0.25 by default)
  // maximum 100%
  return +(Math.round(Math.min(val, 1) * 1 / interval) * interval).toFixed(2) * 100;
}
export function customPPPRound(val) {
  // Round default prices to neareset 50 cents, and max at $11
  var round_to = 0.5;
  var rounded = round_to * Math.round(val / round_to); // round to nearest 0.50 cents
  return Math.min(Math.max(rounded, 4), 11);
}
function customJurisdictionRounding(local, state, federal) {
  var total_rounded;

  // T21 we must round coverage/contribution values to nearest 1 percent for the bootstrap progress bars.
  // However this presents a problem when the total is more than 100% (due to rounding)
  // CT, TX, GA, AK, OH, MS have rounding issues (rounding results in 101% because of trailing 5s)

  // Round to nearest 1% and make sure total is 100% (coverage)
  var local_rounded, state_rounded, federal_rounded;
  local_rounded = Math.round(local * 100);
  state_rounded = Math.round(state * 100);
  federal_rounded = Math.round(federal * 100);
  if (local > 0 && local < 0.01) {
    local_rounded = 1;
    if (state > 0) {
      state_rounded = Math.floor(state * 100);
      federal_rounded = Math.round(federal * 100);
    } else {
      state_rounded = Math.round(state * 100);
      federal_rounded = Math.floor(federal * 100);
    }
  } else {
    total_rounded = Math.round(local * 100) + Math.round(state * 100) + Math.round(federal * 100);
    if (total_rounded > 100) {
      // Rounded total more than 100% (which will display incorrectly with a bootstrap progress bar)
      // so we need to find one value to round down
      if (state_rounded == state * 100) {
        federal_rounded = Math.floor(federal * 100);
      } else {
        state_rounded = Math.floor(state * 100);
      }
    }
  }
  return {
    local: local_rounded,
    state: state_rounded,
    federal: federal_rounded
  };
}
export var default_params = mapValues(original_params, function (d) {
  var rounded_coverage = customJurisdictionRounding(+d.t21_local, +d.t21_state, +d.t21_federal);
  var rounded_contribution = customJurisdictionRounding(+d.t21_local_contr, +d.t21_state_contr, +d.t21_federal_contr);
  return extend({}, {
    original: {
      ppp: +d.ppp,
      w: +d.w,
      w_law: +d.w_old,
      r: +d.r,
      b: +d.b,
      t21_local: +d.t21_local,
      t21_state: +d.t21_state,
      t21_federal: +d.t21_federal,
      t21_federal_only: +d.t21_federal_only,
      t21_local_contr: +d.t21_local_contr,
      t21_state_contr: +d.t21_state_contr,
      t21_federal_contr: +d.t21_federal_contr,
      t21_state_year: +d.t21_state_year,
      expenditure_percent_cdc: +d.expenditure_percent_cdc
    },
    unrounded_ppp: d.ppp,
    ppp: customPPPRound(d.ppp),
    w_law: Math.round(d.w_old * 100),
    w_reported: Math.round(d.w * 100),
    w: customRound(d.w),
    r: customRound(d.r),
    b: customRound(d.b),
    expenditure_percent_cdc: customRound(d.expenditure_percent_cdc, 0.1),
    t21_local_label: (d.t21_local * 100).toFixed(1),
    t21_state_label: (d.t21_state * 100).toFixed(1),
    t21_federal_label: (d.t21_federal * 100).toFixed(1),
    t21_local_contr_label: (d.t21_local_contr * 100).toFixed(1),
    t21_state_contr_label: (d.t21_state_contr * 100).toFixed(1),
    t21_federal_contr_label: (d.t21_federal_contr * 100).toFixed(1),
    t21_local: rounded_coverage.local,
    t21_state: rounded_coverage.state,
    t21_federal: rounded_coverage.federal,
    t21_local_contr: rounded_contribution.local,
    t21_state_contr: rounded_contribution.state,
    t21_federal_contr: rounded_contribution.federal,
    t21_state_year: d.t21_state_year
  });
});
export var us_states = mapValues(original_params, function (d, key) {
  return {
    value: key,
    label: d.name,
    population: d.population,
    expenditure_cdc: d.expenditure_cdc
  };
});
export var us_states_array = sortBy(map(keys(us_states), function (key) {
  var row = us_states[key];
  row.id = key;
  return row;
}), function (row) {
  // order by state name, but put United States on top
  if (row.id == 'US') return ' ';else return row.label;
});