import App from '../app';
import { Component } from 'backbone-component';
import { changer } from '../utils';
var ProgressBarStackedComponent = Component.extend({
  className: 'progress-bar-stacked',
  tagName: 'div',
  template: App.template('progress-bar-stacked'),
  initialize: function initialize() {
    var _this = this;
    this.change = changer();
    this.listenTo(this.props, 'change:options', function () {
      _this.change(_this.render, _this);
    });
  }
});
export default ProgressBarStackedComponent;
ProgressBarStackedComponent.registerAs('progress-bar-stacked');